
import HeaderDownloadButton from './HeaderDownloadButton.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import localforage from 'localforage';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { UserDto } from '../../../api/types';
import router from '../../../router';
import store from '../../../store';
import HeaderInfoButton from './HeaderInfoButton.vue';
import ClientModule from '@/store/modules/ClientModule';

@Component({ components: { HeaderInfoButton, HeaderDownloadButton } })
export default class AppHeader extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  drawer = false;

  get user(): UserDto {
    return this.userModule.currentUser;
  }

  get isLoggedOut(): boolean {
    return this.user._id === '';
  }

  get logo(): string {
    return require('@/assets/images/leo_lesetest_logo.png');
    // return require('@/assets/images/logo.png');
  }

  get isLogin(): boolean {
    return (
      this.$route.name === 'school' ||
      this.$route.name === 'forgotPassword' ||
      this.$route.name === 'resetPassword'
    );
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get isSmall(): boolean {
    return this.$vuetify.breakpoint.smOnly;
  }

  get isTeacherView(): boolean {
    return this.$route.path.includes('students');
  }

  get isSmOrSmaller(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get isDemoVersion(): boolean {
    return this.schoolModule.isTutorialOnlyVersion;
  }

  async logout(): Promise<void> {
    const prefix = getSchoolPrefix();
    await localforage.clear();
    this.schoolModule.reset();
    this.clientModule.reset();
    this.userModule.logout();
    store.commit('logout');
    router.push({
      name: 'school',
      replace: true,
      params: { schoolName: prefix },
    });
    localStorage.clear();
  }
}
