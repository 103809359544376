
import router from '@/router';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component
export default class BackButton extends Vue {
  @Prop()
  title?: string;

  userModule: UserModule = getModule(UserModule, this.$store);

  get isAuth(): boolean {
    return this.userModule.currentUser._id !== '';
  }

  action(): void {
    if (!this.isAuth) {
      router.push({
        name: 'school',
        params: { schoolName: getSchoolPrefix() },
      });
    } else if (this.$route.name === 'imprint') {
      router.back();
    } else {
      router.push({
        name: 'school',
        params: { schoolName: getSchoolPrefix() },
      });
    }
  }
}
