
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component
export default class HeaderDownloadButton extends Vue {
  @Prop() link!: string;
  @Prop() name!: string;
  @Prop() icon!: string;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  loading = false;

  async downloadPDF(): Promise<void> {
    const book =
      this.name === this.$t('teacher.manual') ? 'manual' : 'practice';
    try {
      this.loading = true;
      await this.schoolModule.downloadBook(book);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loading = false;
    }
  }
}
