
import { Client } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';

@Component
export default class LicenseWarning extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  showWarning = false;

  get validDays(): number {
    return this.schoolModule.validDaysLeft;
  }

  get isSchool(): boolean {
    return this.schoolModule.clientType === Client.school;
  }

  get isTherapy(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  get isWarningExpiration(): boolean {
    return this.validDays < 10;
  }

  get isExpired(): boolean {
    return this.validDays <= 0;
  }

  get hasNoValidTestsLeft(): boolean {
    return this.isTherapy ? this.schoolModule.hasNoValidTestsLeft : false;
  }

  get url(): string {
    return this.isSchool
      ? (this.$i18n.t('license.url.school') as string)
      : this.isTherapy
      ? (this.$i18n.t('license.url.therapy') as string)
      : (this.$i18n.t('license.url.parent') as string);
  }

  mounted() {
    if (this.validDays < 10 || this.hasNoValidTestsLeft) {
      this.showWarning = true;
    }
  }

  closeWarning(): void {
    this.showWarning = false;
  }
}
