
import router from '@/router';
import Vue from 'vue';
import Component from 'vue-class-component';
import UserModule from '@/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';
import { Client, Role } from '@/api/types';
import { Prop } from 'vue-property-decorator';
import { getSchoolPrefix } from '@/utils/common';

@Component
export default class AdminButton extends Vue {
  @Prop({ default: '' }) grade?: string;

  userModule: UserModule = getModule(UserModule, this.$store);

  text = 'Schüler';
  icon = 'mdi-account-group-outline';

  get isOnTeachers(): boolean {
    const isTeachers = this.$route.name === 'teachers';
    this.text = isTeachers ? 'Schüler' : 'Lehrer';
    this.icon = isTeachers
      ? 'mdi-account-group-outline'
      : 'mdi-monitor-dashboard';
    return isTeachers;
  }

  get isAdminAlsoTeacher(): boolean {
    return (
      this.grade != '' &&
      this.grade != null &&
      this.userModule.currentUser.role === Role.admin &&
      this.userModule.currentUser.clientType === Client.school
    );
  }

  get isAdminTherapist(): boolean {
    return (
      this.userModule.currentUser.role === Role.admin &&
      this.userModule.currentUser.clientType === Client.therapy
    );
  }

  created(): void {
    const isTeachers = this.$route.name === 'teachers';

    if (this.userModule.currentUser.clientType === Client.school) {
      this.text = isTeachers ? 'Schüler-Übersicht' : 'Lehrer-Übersicht';
    } else if (this.userModule.currentUser.clientType === Client.therapy) {
      this.text = isTeachers ? 'Patienten-Übersicht' : 'Therapeuten-Übersicht';
    }
    this.icon = isTeachers
      ? 'mdi-account-group-outline'
      : 'mdi-monitor-dashboard';
  }

  goToPage(): void {
    const prefix = getSchoolPrefix();
    this.isOnTeachers
      ? router.push({ name: 'students', params: { schoolName: prefix } })
      : router.push({ name: 'teachers', params: { schoolName: prefix } });
  }
}
