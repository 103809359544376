
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import LoginInfoBar from '../../base/LoginInfoBar.vue';
import BackButton from './BackButton.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('password'),
    },
  },
  components: { LoginInfoBar, BackButton },
})
export default class ResetPasswordForm extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  showPassword = false;
  showConfirmPassword = false;

  email = '';
  password = '';
  confirmPassword = '';
  token = '';

  loading = false;

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  mounted(): void {
    this.token = this.$route.query.token as string;
  }

  get forgotPasswordLink(): string {
    return `/#/${getSchoolPrefix()}/schule/forgot-password`;
  }

  async setPassword(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        await this.userModule.resetPassword({
          email: this.email,
          password: this.password,
          token: this.token,
        });
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.password.success').toString(),
          isSuccess: true,
        });
      } catch (error: any) {
        if (error?.response?.status === 400) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.password.invalid').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.password.error').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
    }
  }

  goToLogin(): void {
    this.$router.push({
      name: 'school',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  clear(): void {
    this.$v.$reset();
    this.email = '';
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }

  get passwordErrors(): string[] {
    const passwordLength = this.$i18n.t('snackbar.error.passwordLength');
    const requiredMessage = this.$i18n.t('snackbar.error.password');
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength && errors.push(passwordLength.toString());
    !this.$v.password.required && errors.push(requiredMessage.toString());
    return errors;
  }

  get confirmPasswordErrors(): string[] {
    const invalidPassword = this.$i18n.t('snackbar.error.passwordLength');
    const sameAs = this.$i18n.t('snackbar.error.sameAs');
    const requiredMessage = this.$i18n.t('snackbar.error.password');
    const errors: string[] = [];
    if (!this.$v.confirmPassword.$dirty) return errors;
    !this.$v.confirmPassword.minLength &&
      errors.push(invalidPassword.toString());
    !this.$v.confirmPassword.sameAsPassword && errors.push(sameAs.toString());
    !this.$v.confirmPassword.required &&
      errors.push(requiredMessage.toString());
    return errors;
  }
}
