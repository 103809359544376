
import EvaluationButton from '../students/table/EvaluationButton.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import UserModule from '@/store/modules/UserModule';

@Component({
  components: { EvaluationButton },
})
export default class StudentsTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  @Prop() grade!: string;

  disableFormA: string[] = [];
  disableFormB: string[] = [];

  tests = [
    {
      id: 1,
      class: '',
      doneA: false,
      doneB: false,
    },
    {
      id: 2,
      class: '',
      doneA: false,
      doneB: false,
    },
  ];

  get headers(): any {
    return [
      { text: 'Test', value: 'studentId', align: 'center' },

      {
        text: 'Klasse',
        value: 'grade',
      },
      { text: 'Test', value: 'test', align: 'center', sortable: false },
      {
        text: 'Fertig',
        value: 'finished',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Auswertung',
        value: 'evaluation',
        sortable: false,
      },
    ];
  }

  get schoolSuffix(): string {
    return this.userModule.schoolSuffix;
  }

  // async save(user: UpdateUserDto): Promise<void> {
  //   try {
  //     await this.schoolModule.updateUser({
  //       id: this.editedItem._id,
  //       updateUser: user,
  //     });
  //     if (this.editedIndex > -1) {
  //       Object.assign(this.users[this.editedIndex], this.editedItem);
  //     } else {
  //       console.log('EDITED USERS: ', this.editedItem);
  //     }
  //     this.$emit('snackbar', {
  //       message: 'Schüler/in erfolgreich geändert!',
  //       isSuccess: true,
  //     });
  //   } catch (error) {
  //     this.$emit('snackbar', {
  //       message: 'Schüler/in konnte nicht geändert werden!',
  //       isSuccess: false,
  //     });
  //   }
  //   this.close();
  // }
}
