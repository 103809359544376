
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import SnackbarModule from '../../../store/modules/SnackbarModule';

@Component({
  components: {},
})
export default class TeacherSnachbar extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  get text(): string {
    return this.isSuccess ? this.message : 'Leider ist etwas schiefgelaufen.';
  }

  get snackbar(): boolean {
    return this.snackbarModule.showSnackbar;
  }

  get message(): string {
    return this.snackbarModule.message;
  }

  get timeout(): number {
    return this.snackbarModule.timeout;
  }

  get color(): string {
    return this.isSuccess ? 'success' : 'error';
  }

  get isSuccess(): boolean {
    return this.snackbarModule.isSuccess;
  }
}
