
import LicenseWarning from './LicenseWarning.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import { Role } from '../../../api/types';
import LoginInfoBar from '../../base/LoginInfoBar.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    username: { required, minLength: minLength(3) },
    password: { required, minLength: minLength(3) },
  },
  components: { LoginInfoBar, LicenseWarning },
})
export default class AdminLoginContainer extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  isDisabled = false;
  username = '';
  password = '';
  show = false;
  loading = false;

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  get school(): string {
    return this.schoolModule.schoolName;
  }

  get licenseExpired(): boolean {
    return this.schoolModule.licenseExpired;
  }

  goHome(): void {
    this.$router.push({
      name: 'login',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  goToForgotPassword(): void {
    this.$router.push({
      name: 'forgotPassword',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  async login(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        await this.userModule.login({
          username: this.username.trim(),
          password: this.password.trim(),
        });
        await this.userModule.whoAmI();
        const prefix = getSchoolPrefix();

        const isNoSchoolMatch =
          this.schoolModule.schoolId?.toString() !==
          this.userModule.currentUser.schoolId.toString();

        if (isNoSchoolMatch) {
          this.userModule.logout();
          throw new Error('No school match');
        }

        const role = this.userModule.currentUser.role;

        // todo if first login update user as activated!!!
        if (this.userModule.user.firstLogin) {
          await this.userModule.updateUser({
            data: { firstLogin: false },
            id: this.userModule.user._id,
          });
        }

        if (role === Role.superadmin) {
          this.$router.push({
            name: 'groups',
            replace: true,
            params: { schoolName: prefix },
          });
        } //push route according to role!
        else if (role === Role.admin) {
          this.$router.push({
            name: 'teachers',
            replace: true,
            params: { schoolName: prefix },
          });
        } else if (
          role === Role.teacher ||
          role === Role.therapist ||
          role === Role.parent
        ) {
          this.$router.push({
            name: 'students',
            replace: true,
            params: { schoolName: prefix },
          });
        } else {
          this.$router.push({
            name: 'school',
            replace: true,
            params: { schoolName: prefix },
          });
        }
      } catch (error: any) {
        if (error.message === 'No school match') {
          console.log(error.message);
        }

        if (error.response?.data?.message) {
          console.log(error.response.data.message);
        }

        if (error.response?.data?.statusCode === 400) {
          console.log(error);
          this.snackbarModule.showMessage({
            message: 'Fehler: Versuchen Sie es später erneut!',
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: 'Email oder Passwort falsch!',
            isSuccess: false,
          });
        }
      }
      this.loading = false;
    }
  }

  clear(): void {
    this.$v.$reset();
    this.username = '';
    this.password = '';
  }

  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.minLength &&
      errors.push('Dein Benutzername ist mind. 3 Buchstaben lang!');
    !this.$v.username.required && errors.push('Wie lautet dein Benutzername?');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength &&
      errors.push('Dein Passwort hat mind. 3 Buchstaben!');
    !this.$v.password.required && errors.push('Bitte gib dein Passwort ein!');
    return errors;
  }
}
