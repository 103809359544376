
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import router from '../../../router';

@Component
export default class AdminFooter extends Vue {
  goToContact(): void {
    router.push({
      name: 'imprint',
      hash: '#legal',
      params: { schoolName: getSchoolPrefix() },
    });
  }
  goToLegal(): void {
    router.push({
      name: 'imprint',
      hash: '#legal',
      params: { schoolName: getSchoolPrefix() },
    });
  }
  goToDataPolicy(): void {
    router.push({
      name: 'imprint',
      hash: '#data-policy',
      params: { schoolName: getSchoolPrefix() },
    });
  }
}
