
import router from '@/router';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import LoginInfoBar from '../../base/LoginInfoBar.vue';
import BackButton from './BackButton.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  components: { LoginInfoBar, BackButton },
})
export default class AdminLoginContainer extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  isDisabled = false;
  email = '';
  show = false;
  isLoading = false;

  get school(): string {
    // return this.userModule.currentUser.schoolName!;
    return 'VS Alphaport';
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  async sendForgotPassword(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.isLoading = true;
        await this.userModule.forgotPassword(this.email);
        //push route according to role!
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.email.success').toString(),
          isSuccess: true,
        });
        setTimeout(async () => {
          router.push({
            name: 'school',
            replace: true,
            params: { schoolName: getSchoolPrefix() },
          });
        }, 5000);
      } catch (error) {
        // console.log(error);
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.email.error').toString(),
          isSuccess: false,
        });
      }
      this.isLoading = false;
    }
  }

  clear(): void {
    this.$v.$reset();
    this.email = '';
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }
}
