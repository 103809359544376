
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class HeaderInfoButton extends Vue {
  @Prop() link!: string;
  @Prop() name!: string;
  @Prop() icon!: string;

  goToHelperSite(): void {
    console.log('GO TO infos!');
  }
}
